window.jQuery = $;
window.$ = $;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery'
require("bootstrap")
require("@popperjs/core")

import select2 from 'select2'
import 'select2/dist/css/select2.css'
import "./plugin/popover.js"
import "./plugin/select2.js"
import "./plugin/tooltip.js"

import "./plugin/toggle-password.js"
import './plugin/confirm-submit.js'

require('datatables.net-bs')(window, $)
require('datatables.net-buttons-bs')(window, $)
require('datatables.net-buttons/js/buttons.colVis.js')(window, $)
require('datatables.net-buttons/js/buttons.html5.js')(window, $)
require('datatables.net-buttons/js/buttons.print.js')(window, $)
require('datatables.net-responsive-bs')(window, $)
require('datatables.net-select')(window, $)

Rails.start()
Turbolinks.start()
ActiveStorage.start()
