document.addEventListener("turbolinks:before-cache", function() {
  if ($('.js-source-select2').first().data('select2') != undefined) $('.js-source-select2').select2('destroy');
});

document.addEventListener("turbolinks:load", function() {
  $('.select2-container').remove() //remove the select2 container DOM
  $('.select2-input').select2(); // Select2 will just reinit the DOM

  $(".js-source-select2").select2();
});
